<template>
  <ion-page>
    <template v-if="game">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
          <ion-title>{{ game.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        <div id="container">
          <div class="info">
              {{ game.description }}
          </div>
          <div v-if="Object.keys(game.configs).length > 0" class="configs">
            <div v-if="game.configs.club" class="config">
              <h3>{{ planGame.config.club }}</h3>
              <div>Club</div>
            </div>
            <div v-if="game.configs.distance" class="config">
              <h3>{{ planGame.config.distance }}yds</h3>
              <div>Distance</div>
            </div>
          </div>

        </div>
      </ion-content>
    </template>
  </ion-page>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
} from '@ionic/vue';
import { golfSharp } from 'ionicons/icons';

export default {
  name: 'PlanGameDetails',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  setup() {
    return {
      golfSharp
    };
  },

  created() {
  },

  data() {
    return {};
  },

  computed: {
    planId() {
      return parseInt(this.$route.params.planId, 10);
    },
    planGameId() {
      return parseInt(this.$route.params.planGameId, 10);
    },
    plan() {
      return this.global.plans.getById(this.planId);
    },
    planGame() {
      return this.plan?.planGames.find((pg) => pg.id === this.planGameId) || {};
    },
    gameId() {
      return this.planGame.gameId;
    },
    game() {
      return this.global.games.getById(this.gameId);
    },
  },

  methods: {
  },
}
</script>

<style scoped lang="scss">
  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }

  .configs {
    display: flex;
    margin: 1.5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    .config {
      margin: 0 1.5rem;

      > div {
        text-transform: uppercase;
        font-weight: bold;
        color: #666;
        font-size: 1.1rem;
      }
    }
  }

</style>